import Swiper from 'swiper';
import { Pagination, Autoplay, EffectCreative } from 'swiper/modules';
Swiper.use([Pagination, Autoplay, EffectCreative]);


export function topHeroSlider() {
	const slider1 = new Swiper('.hero__slider__swiper', {
		effect: "creative",
		slidesPerView: 1,
		keyboard: false,
		allowTouchMove: false,
		mousewheel: false,
		speed: 1100,
		autoplay: {
			delay: 4000,
			stopOnLast: false,
			disableOnInteraction: false,
		},
		pagination: {
			el: '.swiper-pagination',
		},
		creativeEffect: {
			prev: {
				// will set `translateZ(-400px)` on previous slides
				translate: [0, 0, 100],
			},
			next: {
				// will set `translateX(100%)` on next slides
				translate: [0, 0, -1],
			},
		},
		on: {
			slideChangeTransitionEnd: function () {

			}
		},
	});

}
