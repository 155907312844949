import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

//ヒーロースライダー
import { topHeroSlider } from './module/top/topHeroSlider';

//oddmator
import { odoMator } from './module/top/odometer.min.js';

//serviceSlide
import { serviceScroll } from './module/top/serviceScroll';

//fullpage
//import {topfullpage} from './module/top/fullpage.js';

//ブロックアニメーション
import { animBlock } from './module/common/animBlock';

const body = document.querySelector('body');

let timerid3 = setTimeout(() => {
	body.classList.add('loading-start');

}, 300);

window.addEventListener("load", (event) => {
	//ヒーロースライダー
	topHeroSlider();

	//数字カウント
	odoMator();

	//
	serviceScroll();
	//body.classList.add('loading-end');

	let timerid2 = setTimeout(() => {
		topHeroSlider();
		body.classList.add('loading-end');
	}, 1900);


	let timerid3 = setTimeout(() => {
		animBlock();
	}, 2300);

});
