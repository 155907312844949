import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export function serviceScroll() {
	const sections = document.querySelectorAll(".service__cont__item__outer");
	const observerRoot = document.querySelector(".service__cont");
	const servicePager = document.querySelector(".service__cont__pager");
	const servicePagerBtn = document.querySelectorAll(".service__cont__pager__list button");

	const winH = window.scrollY;

	const lists = servicePagerBtn;
	lists.forEach(function(listItem,i){
		listItem.addEventListener("click", e => {

			let tagetTop = observerRoot.getBoundingClientRect().top + winH;
			let tagetheight = observerRoot.getBoundingClientRect().height / (sections.length + 1) * i;

			window.scrollTo({
				top: tagetTop + tagetheight,
				behavior: "smooth"
			});
		});
	});
	console.log(window.innerWidth);
	if( window.innerWidth < 600){

		sections.forEach((target) => {

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: target,
					start: "top center",
					toggleClass: {
						targets: target,
						className: "active",
					  },
					  once: true,
				},
			});
		});
	}else{
		const tl = gsap.timeline({
			scrollTrigger: {
				pin: true,
				trigger: observerRoot,
				start: "top top",
				end: "bottom top",
				invalidateOnRefresh: true,
				anticipatePin: 1,
				pinSpacing: false,
				scrub: 1,
				onUpdate({ progress, direction, isActive }) {
					//console.log(progress);
					if (progress > 0 && progress <= .25) {
						sections.forEach(item => {
							item.classList.remove("active");
						});
						sections[0].classList.add("active");

						servicePager.classList.add("active");

						servicePagerBtn.forEach(item => {
							item.classList.remove("active");
						});
						servicePagerBtn[0].classList.add("active");
					} else if (progress > .25 && progress <= .5) {
						sections.forEach(item => {
							item.classList.remove("active");
						});
						sections[1].classList.add("active");

						servicePager.classList.add("active");

						servicePagerBtn.forEach(item => {
							item.classList.remove("active");
						});
						servicePagerBtn[1].classList.add("active");

					} else if (progress > .5 && progress <= .75) {
						sections.forEach(item => {
							item.classList.remove("active");
						});
						sections[2].classList.add("active");

						servicePager.classList.add("active");

						servicePagerBtn.forEach(item => {
							item.classList.remove("active");
						});
						servicePagerBtn[2].classList.add("active");
					} else if (progress > .75) {
						sections.forEach(item => {
							item.classList.remove("active");
						});

						servicePager.classList.remove("active");
					}
				}
			},
		});
	}

}

/*
var e=document.querySelector(".fv .parallax_section");
r.p8.to(e,{ease:"none",scrollTrigger:{trigger:e,start:"top top",end:"bottom top",scrub:0,onUpdate:function(t){
	var n=.7*t.progress,o=200*n*n+"px";
r.p8.to(e,{y:o,duration:.01}),e.style.filter="brightness("+(1-n)+")"}}});
*/
