import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
export function animBlock() {
	gsap.utils.toArray(".js--anim--body").forEach((target) => {

		const items = target.querySelectorAll(".js--anim--item");

		const tl = gsap.timeline({
			scrollTrigger: {
				trigger: target,
				start: "top bottom-=30%",
			},
		});
		items.forEach(item => {

			tl.fromTo(
				item,
				{
					y: 20,
					autoAlpha: 0,
				},
				{
					y: 0,
					autoAlpha: 1,
					duration: .9,
					ease: "expo.out",
				}, "<.15",

			);

		});
	});

}
